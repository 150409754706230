
import { defineComponent } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  name: "invite-friends-modal",
  components: {},
  data(){
    return {
      amount: '',
      notify: true,
      loading: false
    }
  },
  methods: {
    async update(){

      if(this.amount.trim().length == 0){
        return Swal.fire({
          text: 'Please enter a valid number to proceed.',
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Okay",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        })
      }

      try{
        this.loading = true
        // await this.axios.post(`/wallet/${this.$route.params.uid}`, {
        //   amount: this.amount,
        //   notify: this.notify
        // })
        Swal.fire({
          text: 'The user\'s wallet balance was updated successfully',
          icon: "success",
          buttonsStyling: false,
          confirmButtonText: "Refresh",
          customClass: {
            confirmButton: "btn fw-bold btn-light-success",
          },
        }, ()=>{
          console.log('eti')
        })
        
        this.loading = false
        setTimeout(()=>{
          this.$router.go(0)
        }, 2000)
      }catch(e){
        
        this.loading = false
        Swal.fire({
          text: 'There was an error updating the user\'s wallet balance',
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Try again!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        })
      }
    }
  }
});
